<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Deposited Order History</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body" v-if="deposit">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Collected Amount" vid="collected_amount" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="collected_amount">Collected Amount <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="collected_amount" v-model="deposit.collected_amount" class="form-control" placeholder="Hub Cost" readonly />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Hub Cost" vid="hub_cost" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="hub_cost">Hub Cost <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="hub_cost" v-model="deposit.hub_cost" class="form-control" placeholder="Hub Cost" readonly />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Rider Advanced" vid="rider_advanced" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="rider_advanced">Rider Advanced <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="rider_advanced" v-model="deposit.rider_advanced" class="form-control" placeholder="Rider Advanced" readonly />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>                                        
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Date" vid="date" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="date">Date <span class="text-danger" title="Required">*</span></label>
                                                <input type="date" id="date" v-model="deposit.date" class="form-control" required disabled />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="mobile">Amount <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="amount" v-model="deposit.amount" class="form-control" placeholder="Amount" readonly />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Available" vid="available_amount" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="available_amount">Available <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="available_amount" v-model="deposit.available_amount" class="form-control" disabled />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <ValidationProvider name="Deposit Method" vid="deposit_method" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="deposit_method">Deposit Method <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="deposit_method"
                                                    v-model="deposit.deposit_method"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= depositMethods
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="deposit.deposit_method == 3">
                                        <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                            <div class="form-group">
                                                <label for="hub_id">Carried By <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="carried_by"
                                                    v-model="deposit.carried_by"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= riderList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="deposit.deposit_method != 3">
                                        <div class="form-group">
                                            <ValidationProvider name="Trnx ID" vid="trnxId" v-slot="{errors}" rules="required">
                                                <label for="note">Trnx ID <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="trnxId" v-model="deposit.trnxId" class="form-control" placeholder="Transaction ID" />
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <ValidationProvider name="Note" vid="note" v-slot="{errors}" rules="required">
                                                <label for="note">Note <span class="text-danger" title="Required">*</span></label>
                                                <textarea id="note" v-model="deposit.note" class="form-control" placeholder="Note maximum 200 word" readonly> </textarea>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="title">Rider <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="hub_id"
                                                v-model="search.rider_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= riderList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:26px">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData">Search</button>
                                            <span v-if="summary" style="margin-left:10px; font-size:18px">
                                                Parcel: <b>{{ summary.total_order }},</b>
                                                Collected: <b> {{ summary.total_collected ? summary.total_collected : 0 }} Tk</b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Order ID</th>
                                                    <th>Merchant</th>
                                                    <th>Customer</th>
                                                    <th>Address</th>
                                                    <th>Status</th>
                                                    <th>Price</th>
                                                    <th>Collected</th>
                                                    <th>Destination Hub</th>
                                                    <th>Order Date</th>
                                                    <th>Instruction</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ item.id }}</td>
                                                    <td>{{ item.merchant.business }}</td>
                                                    <td>{{ item.name }}, {{ item.mobile }}</td>
                                                    <td>{{ item.address }}</td>
                                                    <td><span :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>
                                                    <td>{{ item.price }}</td>
                                                    <td>{{ item.collected }}</td>
                                                    <td>{{ item.destination ? item.destination.name : '' }}</td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.instruction }}</td>
                                                </tr>
                                            </tbody>
                                        </table>           
                                    </div>   
                                     <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div> 
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
import config from '@/config'

export default {
    name:'RiderAssign',
    data(){
        return {
            loader: false,
            listData: [],
            riderDetails: '',
            summary: null,
            deposit: null,
            search: {
                rider_id: ''
            },
            depositMethods: [
                { id: 1, text: 'Bank' },
                { id: 2, text: 'Bkash' },
                { id: 3, text: 'Cash' },
            ],
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created() {
        this.loadData()
        this.loadSummary()
    },
    computed : {
        authUser () {
            return this.$store.state.authUser
        },
        customState () {
            return this.$store.state
        },
        riderList () {
            if (this.authUser.role_id == 7) {
                return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
            }
            
            return this.$store.state.commonObj.riderList
        }
    },
    methods:{
        searchData() {
            this.loadData()
            this.loadSummary()
        },
        getRiderDetails (riderId) {
            const rider = this.$store.state.commonObj.riderList.find(el => el.id == riderId)
            this.riderDetails = rider ? (rider.text + ', '+ rider.mobile) : ''
        },
        async loadData() { 
            const params = Object.assign(this.search, { deposit_id: this.$route.query.deposit_id })
            const response = await config.getData('/hub/deposited/list', params)
            this.loader = false
            if (response.success){
                this.listData = response.data.data
            } else {
                this.listData = []
            } 
        },
        async loadSummary() { 
            const params = Object.assign(this.search, { deposit_id: this.$route.query.deposit_id })
            const response = await config.getData('/hub/deposited/summary', params)
            this.loader = false
            if (response.success){
                this.summary = response.data
                this.deposit = response.deposit
            } else {
                this.summary = null
                this.deposit = null
            } 
        },
        getStatus (status) {
            const tmpStatus = this.customState.statusList.find(tmp => tmp.id == status)
            return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
        }
    }
}
</script>